@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Playfair+Display:wght@700&family=Roboto:wght@300;400;700&display=swap");

body {
  margin: 0 !important;
  padding: 0 !important;
}

html {
  margin: 0 !important;
  padding: 0 !important;
}

.container {
  @media (min-width: 2000px) {
    max-width: 1500px;
  }
  @media (max-width: 1000px) {
    max-width: 95vw !important;
  }
}

body {
  background-color: white !important;
  overflow-x: hidden !important;
  max-width: 100vw !important;
}

.m-only {
  @media (min-width: 769px) {
    display: none;
  }
}

.d-only {
  @media (max-width: 768px) {
    display: none;
  }
}

.display-none {
  display: none;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.playfair {
  font-family: "Playfair Display", serif;
}

.df-display {
  font-family: "DM Serif Display", serif;
}

.font-300 {
  font-weight: 300 !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.font-white {
  color: white;
}

.font-black {
  color: black;
}

.font-orange {
  color: #db683b;
}

.font-green {
  color: #6ad768;
}

.no-a {
  text-decoration: none !important;
  pointer-events: none;
}

.transition-all {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.navbar-full {
  background-color: transparent;
  color: white;
  width: 100vw !important;

  position: fixed;
  top: 0;
  z-index: 10;

  &.absolute-pos {
    position: absolute;
  }

  .clickable {
    cursor: pointer;
  }

  &.white-bg {
    background-color: black;
  }

  .container {
    width: 100% !important;
  }

  .logo-col {
    a {
      display: inline-block;
    }
    img {
      max-width: 15vw;

      &.white-logo {
        filter: contrast(0) brightness(500%);
      }

      &.blue-logo {
        filter: contrast(100%) brightness(100%);
      }
    }
  }

  .link-col {
    .link-row {
      float: right;

      display: flex;
      align-items: center;
      height: 100%;

      .clickable {
        text-decoration: none !important;
        cursor: pointer;

        &:hover {
          color: #db683b !important;
        }
      }
      a {
        color: white !important;
        margin-left: 1rem;
        margin-right: 1rem;

        &.font-orange {
          color: #db683b !important;

          &:hover {
            text-decoration: none !important;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .m-links {
      background-color: inherit;
      max-height: 0 !important;
      overflow: hidden !important;

      .d-flex {
        flex-direction: column !important;
      }

      .clickable {
        text-decoration: none !important;
        cursor: pointer;

        color: white;
        font-size: 1.5rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        &:hover {
          color: #db683b !important;
        }
      }

      a {
        color: white;
        font-size: 1.5rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        &.font-orange {
          color: #db683b !important;

          &:hover {
            text-decoration: none !important;
          }
        }
      }

      &.expanded {
        max-height: 50vh !important;
      }
    }

    .logo-col {
      max-width: 50%;
      &.less-pad {
        padding-left: 0;
      }
      img {
        max-width: 100%;
      }
    }

    .link-expand-col {
      display: flex;
      align-items: center;
      padding: 0;
      a {
        margin-left: auto;

        &.flip-switch {
          transform: rotate(180deg);
        }
      }
      img {
        -webkit-filter: invert(100%);
        filter: invert(100%);
        width: 5vw;
      }
    }
  }

  @media (min-width: 769px) {
    .m-only {
      display: none !important;
    }
  }
}

.footer-full {
  background-color: #111111;
  color: white;
  width: 100vw !important;

  .map-img {
    background-image: url("./media/map.jpg");
    background-size: cover;
    background-position: 50%;

    filter: brightness(60%) grayscale(200%);

    &:hover {
      filter: brightness(80%) grayscale(200%);
    }
  }

  .copyright {
    color: rgba(255, 255, 255, 0.4);
    font-size: 0.8rem;
  }

  a {
    text-decoration: none !important;
  }

  a:hover {
    color: #db683b;
  }
}

section {
  width: 100vw !important;
  overflow-x: hidden !important;
}

div.body-documents {
  width: 100vw !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  padding-top: 7rem;

  background-color: #111111;

  color: white;

  .fund-docs-container {
    text-align: center;
    min-height: 70vh;

    .row {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    .btn {
      color: white;
      width: 100%;
      background-color: transparent;
      margin: 0;
      padding: 0;

      border-style: solid;
      border-width: 1px;
      border-color: white;
      border-radius: 2rem;

      &:hover {
        background-color: white;
        color: black;
      }
    }

    @media (max-width: 1200px) {
      .row {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media (max-width: 900px) {
      .col-md {
        min-width: 100%;

        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
      }

      .row {
        margin-top: 0 !important;
      }
    }

    @media (max-width: 768px) {
      text-align: left;
      .row {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

div.body-team {
  width: 100vw !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  padding-top: 6rem;

  background-color: #111111;
  color: white;

  a.playfair {
    &.font-white {
      font-size: 1.5rem !important;
      color: rgba(255, 255, 255, 0.6) !important;
    }
  }

  .team-container {
    min-height: 90vh;

    .page-title {
      font-size: 3rem !important;

      @media (max-width: 768px) {
        font-size: 2.5rem !important;
      }
    }
    .team-member {
      flex-direction: column;

      cursor: pointer;

      border-style: solid;
      border-width: 1px;
      border-color: rgba(255, 255, 255, 0.4);
      border-left: none;
      border-right: none;
      border-top: none;
      padding-right: 0;

      .bio {
        max-height: 0 !important;
        overflow: hidden;

        &.expand-team {
          max-height: 100vh !important;
        }
      }

      &:hover {
        .summary {
          .col {
            .playfair {
              font-size: 1.3rem;
            }
          }
        }
      }

      .summary {
        padding-right: 0;
        .col {
          .playfair {
            font-size: 1.25rem;
          }
          .roboto {
            color: rgba(255, 255, 255, 0.4);
          }
        }
        .col-auto {
          align-items: center;
          padding-right: 0;
          .team-b {
            background-color: transparent;
            border: none;
            width: 2vw;
            padding: 0;
            outline: none !important;
            img {
              width: 100%;
              -webkit-filter: invert(100%);
              filter: invert(100%);

              &.flip {
                transform: rotate(-180deg) !important;
              }
            }
          }
        }
      }
    }
  }
}

div.body-market {
  width: 100vw !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;

  padding-top: 6rem;

  .c-dot {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;

    &.green {
      background-color: #b3d768;
    }
    &.blue {
      background-color: #6c92f4;
    }
    &.orange {
      background-color: #ffa800;
    }
  }

  .sellout-container {
    background-color: #111111;
    color: white;

    .scroll-display {
      width: 50vw !important;

      .col {
        height: 5px;
        background-color: rgba(255, 255, 255, 0.3);

        &.white {
          background-color: white;
        }
      }
    }

    .p-block {
      position: relative;
      .roboto {
        max-width: 40%;
        font-size: 1.25rem;

        @media (max-width: 768px) {
          max-width: 100%;
        }
      }

      img {
        position: absolute;
        max-width: 100px;
        top: 30%;
        right: 10%;
      }
    }

    .blended-block {
      .row {
        width: 100%;
        .col {
          padding: 0;
          background-color: #181818;
          .top-sect {
            height: 100%;

            &.first-card {
              display: flex;
              align-items: center;
            }
            .img-sect {
              background-image: url("./media/prop/east7.jpg");
              background-size: cover;
              background-position: 50%;
              height: 50%;

              &.lex-154 {
                background-image: url("./media/prop/154lex.jpg");
              }
              &.lex-1578 {
                background-image: url("./media/prop/1578lex.jpg");
              }
            }

            .stat-sect {
              align-items: center;
              height: 50%;
            }

            .playfair {
              font-size: 1.5rem;
              line-height: 1.5rem;
            }

            .df-display {
              font-size: 1.25rem;
            }

            .roboto {
              color: rgba(255, 255, 255, 0.3);
            }
          }
          .discount-bar {
            background-color: #181818;
            border-top-style: solid;
            border-top-width: 1px;
            border-top-color: rgba(255, 255, 255, 0.9);
            display: flex;
            flex-direction: row;
            align-items: center;

            .roboto {
              //display: block;
              color: rgba(255, 255, 255, 0.3);
            }

            .df-display {
              //float: right;
              text-align: right;
              float: right;
            }
          }
        }

        @media (max-width: 1200px) {
          width: 70% !important;
          margin-left: auto;
          margin-right: auto;

          .col {
            min-width: 30% !important;
            margin: 0 !important;
            margin-bottom: 5rem !important;
            margin-left: 1rem !important;
            margin-right: 1rem !important;
            min-height: 30vh;
          }
        }

        @media (max-width: 1000px) {
          width: 100% !important;
        }
      }

      @media (max-width: 768px) {
        width: 100vw !important;
        overflow-x: scroll !important;
        margin-left: -1rem;

        .row {
          width: 300vw !important;

          .col {
            min-width: 20% !important;
          }
        }
      }
    }
  }

  .market-container {
    h1.market-title {
      font-size: 3.5rem;

      @media (max-width: 768px) {
        margin: 0;
      }
    }

    .graph-stats-container {
      background-color: #f2f2f4;

      .stats-row {
        display: flex;
        align-items: center;
        width: 75%;

        @media (max-width: 768px) {
          width: 100%;
        }

        .col-md {
          padding: 0;
          padding-left: 1rem;
          padding-right: 1rem;
          display: flex;
          justify-content: center;

          .font-300 {
            font-size: 0.8rem;
            margin-bottom: 0;
          }

          &.middle {
            border: none;
            border-left-style: solid;
            border-left-width: 1px;
            border-left-color: rgba(0, 0, 0, 0.2);
            border-right-style: solid;
            border-right-width: 1px;
            border-right-color: rgba(0, 0, 0, 0.2);
          }

          @media (max-width: 768px) {
            display: inline-block;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            width: 100%;
            margin-left: 1.5rem;

            .font-300 {
              font-size: 1rem;
            }
            .font-700 {
              font-size: 1.5rem;
            }

            &.middle {
              border: none;
              border-top-style: solid;
              border-top-width: 1px;
              border-top-color: rgba(0, 0, 0, 0.2);
              border-bottom-style: solid;
              border-bottom-width: 1px;
              border-bottom-color: rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
    .graph-container {
      position: relative;
      background-color: #f2f2f4;

      .roboto {
        font-size: 1.5rem;
      }

      img.d-only {
        width: 100% !important;
      }

      .legend {
        position: absolute;
        bottom: 20%;
        right: -12%;

        .legend-item {
          margin: 0;
          padding: 0;
          background-color: white;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 5px;

          box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);

          .roboto {
            font-size: 0.9rem;
            margin: 0;

            &.font-300 {
              min-width: 11vw;
              padding-right: 1rem;
            }
          }
        }
      }

      @media (max-width: 768px) {
        background-color: transparent;

        .font-700 {
          text-align: left !important;
          font-size: 1rem;
        }
      }
    }
  }
}

div.prop-view-overlay {
  width: 100vw !important;
  height: 100vh !important;
  position: absolute;
  top: 0;

  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;

  .stat-table-title {
    background-color: black !important;
    color: white !important ;
  }

  .table-stats-now {
    &.grey-boy {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
    }
    p {
      width: auto !important;
    }
    .text-right {
      text-align: right !important;
    }
  }

  .prop-memo-link {
    background-color: transparent;
    color: black;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 2rem;
    display: inline-block;
    width: auto !important;
    text-align: center;
    text-decoration: none;

    cursor: pointer;

    &:hover {
      background-color: black;
      color: white !important;
    }
  }

  .prop-view-container {
    background-color: white;
    width: 70%;
    margin: auto;
    position: relative;

    .content-col {
      .description-width {
        min-width: 100% !important;
      }
      .button-col {
        .btn-button {
          text-decoration: none !important;
          color: black !important;
          background-color: transparent !important;
          border: 1px black solid;
          border-radius: 2rem;
          padding: 2rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          cursor: pointer;

          &:hover {
            background-color: rgba(0, 0, 0, 0.8) !important;
            color: white !important;
          }

          &.filled {
            background-color: black !important;
            color: white !important;
          }
        }
      }

      .inner-data {
        min-height: 300px !important;
        display: flex;
        align-items: center;

        .gmap-code {
        }
      }

      .greyed {
        background-color: rgba(0, 0, 0, 0.7) !important;
        color: white !important;
      }
    }

    .img-col {
      background-size: cover;
      background-position: 50%;
      max-width: 40% !important;

      &.img-b-0 {
        background-image: url("./media/prop/east7.jpg");
      }
      &.img-b-1 {
        background-image: url("./media/prop/1578lex.jpg");
      }
      &.img-b-2 {
        background-image: url("./media/prop/154lex.jpg");
      }
    }

    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-color: transparent;

      border-radius: 50%;
      border-style: solid;
      border-width: 1px;
      border-color: black;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      cursor: pointer;
      z-index: 5;

      img {
        margin: 0;
        padding: 0;
        width: 50%;
        height: 50%;
      }

      &:hover {
        background-color: black;

        img {
          filter: invert(100%);
        }
      }
    }

    .prop-memo-link {
      background-color: transparent;
      color: black;
      border-style: solid;
      border-width: 1px;
      border-color: black;
      border-radius: 2rem;
      display: inline-block;
      width: auto !important;
      text-align: center;
      text-decoration: none;

      cursor: pointer;

      &:hover {
        background-color: black;
        color: white !important;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }

    table {
      .address {
        width: 40%;
        padding: 0 !important;
      }
      .img {
        background-size: cover;
        background-position: 50%;

        &.img-b-0 {
          background-image: url("./media/prop/east7.jpg");
        }
        &.img-b-1 {
          background-image: url("./media/prop/1578lex.jpg");
        }
        &.img-b-2 {
          background-image: url("./media/prop/154lex.jpg");
        }
      }

      .stats {
        padding-bottom: 0 !important;
        iframe {
          width: 100% !important;
          height: 100% !important;
          min-height: 250px;
          padding: 0 !important;

          @media (max-width: 1200px) {
            min-height: 200px;
          }
        }
        .col {
          .upper-border {
            border-top-style: solid;
            border-top-width: 1px;
            border-top-color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }

  @media (min-width: 1600px) {
    .prop-view-container {
      width: 60%;
    }
  }

  @media (max-width: 1200px) {
    .prop-view-container {
      width: 90%;
    }
  }
  @media (max-width: 1000px) {
    .prop-view-container {
      width: 95%;
    }
  }

  @media (max-width: 768px) {
    position: relative;
    height: unset !important;
    z-index: 1;

    background-color: white;

    iframe {
      width: 100% !important;
      height: 100% !important;
      min-height: 250px;
      padding: 0 !important;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .full-stat-block {
      border-style: solid;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.3);
      border-left: none;
      border-right: none;
    }

    .upper-border {
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: rgba(0, 0, 0, 0.6);
    }

    .row {
      .col {
        min-width: 40%;

        .df-display {
          font-size: 1.5rem;
        }
      }
    }
  }
}

div.body-portfolio {
  width: 100vw !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  background-color: #111111 !important;
  min-height: 100vh;

  &.abs-no-scroll {
    @media (min-width: 769px) {
      height: 100vh !important;
      overflow-y: hidden !important;
    }
  }

  .head-container {
    padding-top: 6rem;
    padding-bottom: 3rem;
    background-color: #111111;
    color: white;

    .title-col {
      display: flex;
      align-items: center;
      padding-right: 10rem;
      h1.playfair {
        font-size: 3.5rem;
      }

      img {
        max-width: 60px;
        transform: translateX(-100%);
      }
    }

    .prop-col {
      overflow-x: visible;

      .prop-row {
        width: 110%;

        .info-box {
          border-top-style: solid;
          border-top-color: rgba(255, 255, 255, 0.1);
          border-top-width: 1px;
          p.df-display {
            font-size: 1.4rem;

            br {
              display: none;
            }
          }
          .btn-black {
            font-size: 1rem;
            background-color: transparent;
            color: white;
            border-style: solid;
            border-width: 1px;
            border-color: white;
            border-radius: 2rem;

            text-decoration: none;
            cursor: pointer;

            &:hover {
              background-color: white;
              color: black;
            }
          }
        }
      }
    }

    @media (max-width: 1200px) {
      padding-top: 3rem;
      .title-col {
        width: 100%;

        h1 {
          margin-top: 1rem !important;
        }

        img {
          display: none;
        }
      }
      .prop-col {
        .prop-row {
          width: 105%;
        }
      }
    }

    @media (max-width: 768px) {
      .scroll-position {
        .col {
          height: 5px !important;
          background-color: rgba(255, 255, 255, 0.3);

          &.active-white {
            background-color: white;
          }
        }
      }
      .scroll-wrapper {
        max-width: 100vw !important;
        overflow-x: scroll;

        .scroll-container {
          width: 220vw;

          .col {
            .info-box {
              border-top-style: solid;
              border-top-color: rgba(255, 255, 255, 0.1);
              border-top-width: 1px;
              p.df-display {
                font-size: 1.4rem;

                br {
                  display: none;
                }
              }
              .btn-black {
                font-size: 1rem;
                background-color: transparent;
                color: white;
                border-style: solid;
                border-width: 1px;
                border-color: white;
                border-radius: 2rem;

                text-decoration: none;
                cursor: pointer;

                &:hover {
                  background-color: white;
                  color: black;
                }
              }
            }
          }
        }
      }
    }
  }
}

div.body-fund {
  width: 100vw !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  color: black;

  padding-top: 6rem;

  .highlights-section {
    padding-left: 15rem;
    .title-text {
      position: relative;
      display: flex;
      align-items: center;
      height: 40vh;
      h1 {
        font-size: 4.5rem;
        line-height: 5rem;
      }

      img.circle {
        height: 100%;
        position: absolute;
        z-index: -1;
        transform: translateX(40%);
      }
    }

    .row {
      .col-md {
        min-width: 33.3% !important;
        max-width: 50% !important;
        padding: 0;

        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        padding-right: 2rem;

        .df-display {
          font-size: 1.15rem;
        }
      }
    }

    @media (max-width: 1000px) {
      padding-left: 0;

      .title-text {
        padding: 0;
        display: inline-block;
        margin: 0;

        height: unset;

        h1 {
          padding-top: 3rem;
        }

        img.circle {
          height: unset;
          width: 80%;
          left: 0;
          transform: translateX(-35%);
        }
      }

      .row {
        padding-left: 1rem;
        .col-md {
          max-width: unset !important;
        }
      }
    }
  }

  .overview-section {
    max-width: 100% !important;
    position: relative;

    img.circle {
      position: absolute;
      z-index: -1;
      max-width: 20rem;
    }
    .details {
      padding-top: 8rem;
      padding-left: 10rem;
      h1 {
        font-size: 5.5rem;
        line-height: 4.5rem;

        padding-left: 4rem;
      }
      .row-container {
        background-color: white;
        padding: 5rem;
        padding-top: 0;
        padding-right: 0 !important;

        transform: translateY(25%);
        .row {
          padding-right: 0 !important;
          .d-col {
            padding: 0;

            .df-display {
              font-size: 2.5rem;

              a {
                color: black !important;
                font-size: 1.25rem;
              }

              @media (max-width: 1200px) {
                font-size: 2rem;

                a {
                  font-size: 1rem;
                }
              }
            }

            .roboto {
              color: rgba(0, 0, 0, 0.8);

              @media (max-width: 1200px) {
                font-size: 0.9rem;
              }
            }

            @media (max-width: 1000px) {
              min-width: 50% !important;
            }
          }
        }
      }

      @media (max-width: 1000px) {
        padding-left: 6rem;
      }
    }

    @media (max-width: 768px) {
      img.circle {
        width: 45vh;
        height: 45vh;
        right: 0;
        transform: translateX(20%);
      }
      .details {
        padding: 0;

        h1 {
          padding: 0;
          margin-left: 0;
          padding-top: 7rem;
          font-size: 4rem;
          line-height: 4rem;
        }

        .row-container {
          padding: 1rem;
          background-color: transparent;
          transform: none;

          .d-col {
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    img.fund-mobile {
      transform: translateX(20%);
    }
  }
}

div.body-home-auth {
  background-image: url("./media/loginbg.jpg");
  background-size: cover;
  height: 100vh;
  width: 100vw !important;
  overflow-x: hidden !important;

  margin: 0 !important;
  padding: 0 !important;

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    h1 {
      font-size: 4.5vw;
    }

    .auth-links {
      position: absolute;
      bottom: 0;

      display: flex;
      flex-direction: row;
      margin: 0;
      margin-bottom: 3rem;
      padding: 0;

      a {
        border-left-style: solid;
        border-left-width: 1px;
        border-left-color: white;
        margin: 0;
        padding: 0;
        text-decoration: none !important;

        p {
          margin: 0;
          padding: 0;

          &.df-display {
            color: rgba(255, 255, 255, 0.7);
            padding: 0;
            margin-bottom: 0.5rem;
            font-size: 1rem;
            line-height: 0.8rem;
          }

          &.playfair {
            color: white;
            font-size: 1.15rem;
            line-height: 1.45rem;
          }
        }

        &:hover .playfair {
          color: #db683b;
        }
      }

      @media (max-width: 1000px) {
        position: relative;
        a {
          margin-left: 0 !important;
          margin-bottom: 2rem !important;
          min-width: 100% !important;
          margin-top: 2rem !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;
    min-height: 100vh;

    .container {
      height: 100%;

      flex-direction: column;

      &.auth-body {
        padding-top: 8rem;
        padding-bottom: 6rem;
      }

      h1.playfair {
        font-size: 2.25rem;
        padding-bottom: 2.5rem;
        width: 100%;
      }

      .auth-links {
      }
    }
  }
}

div.body-home-about {
  width: 100vw !important;
  overflow-x: hidden !important;
  background-color: #111111;
  color: white;

  .grey-phil {
    margin-top: 4rem !important;
    font-size: 16rem !important;
    color: rgba(255, 255, 255, 0.05);

    transform: translateX(10%);
    transform: translateY(5%);
    transform: translate(10%, 10%);
  }

  .phil-row {
    .col-md-auto {
      padding-right: 15rem !important;
    }
    .icon-col {
      .col-md {
        min-width: 50% !important;

        img {
          max-width: 30px !important;
          &.Located {
            max-width: 20px !important;
          }
        }
      }
    }
  }
}

div.body-home {
  background-image: url("./media/loginbg.jpg");
  background-size: cover;
  min-height: 100vh;
  width: 100vw !important;
  overflow-x: hidden !important;
  margin: 0 !important;
  padding: 0 !important;

  display: flex;
  align-items: center;

  h1.playfair {
    font-size: 4vw;
  }

  .title-col {
    padding-right: 8rem;
    display: flex;
    align-items: center;
  }

  .login-modal {
    background-color: white;
    border-radius: 0.5rem;

    p {
      font-size: 1.25rem;

      &.more-help-text {
        font-size: 1rem !important;
      }
    }

    input {
      background-color: white !important;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.2);
      font-size: 1rem;
    }

    button {
      background-color: #db683b;
      color: white;
      border: none;

      &:hover {
        background-color: rgba(219, 104, 59, 0.8);
      }
    }
  }

  @media (max-width: 768px) {
    display: inline-block !important;
    padding-top: 8rem !important;
    padding-bottom: 6rem !important;
    height: unset;
    min-height: 100vh;
    h1.playfair {
      font-size: 2.25rem;
      padding-bottom: 0rem;
    }
    .title-col {
      padding-right: 0;
    }
  }
}

.display-none {
  display: none !important;
}

.full-pass-body {
  width: 100vw !important;
  overflow-x: hidden !important;

  background-image: url("./media/loginbg.jpg");
  background-size: cover;

  min-height: 100vh !important;

  align-items: center;
  display: flex;

  .verify-modal {
    margin: auto;
    background-color: white;
    border-radius: 0.5rem;
    max-width: 800px !important;
    input {
      background-color: white !important;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.2);
      font-size: 1rem;
    }

    button {
      background-color: #db683b;
      color: white;
      border: none;

      &:hover {
        background-color: rgba(219, 104, 59, 0.8);
      }
    }
  }

  p {
    font-size: 1.25rem;

    &.more-help-text {
      font-size: 1rem !important;
    }
  }
}
